  .tabs-container {
    position: relative;
    width: 100%;
    min-height: 0.0625rem;

    &__navbar {
      display: flex;
      flex-direction: row;
      margin-bottom: 1.25rem;
    }

    &__content {
      width: 100%;
    }
  }